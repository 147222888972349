
import { reactive, ref } from 'vue'
export default {
  name: 'UserType',
  setup(props: any, context: any){
    const selectOptions = reactive({
      userTypeList: [
        {
          code: 1,
          msg: '本人',
          img: require('@/assets/img/upload_user.png'),
          imgChe: require('@/assets/img/upload_user_che.png'),
        },
        {
          code: 2,
          msg: '代理人',
          img: require('@/assets/img/agent.png'),
          imgChe: require('@/assets/img/agent_che.png'),
        },
      ],
      userTypeOptions: [
        {
          label: '自然人',
          value: 1,
        },
        {
          label: '法定代表人',
          value: 2,
        },
      ],
      proxyTypeOptions: [
        {
          label: '法律职业者',
          value: 3,
        },
        {
          label: '近亲属',
          value: 4,
        },
        {
          label: '员工',
          value: 5,
        },
      ]
    })
    const colNum = ref(1)
    const userValue: any = ref(1)
    const proxyValue: any = ref(1)
    const getUserTypeDetail = ( userType: number, proxyType: number ) => {
      userValue.value = userType
      proxyValue.value = proxyType
    }
    const constUserTypeFun = (id: number) => {
      userValue.value = id
      proxyValue.value = id == 1 ? 1 : 3
      context.emit('change-user-type', {
        user: userValue,
        proxy: proxyValue
      })
      // if (id === 1) {
      //   context.emit('usertypefun', { userType: id, proxyType: 1 })
      // } else {
      //   context.emit('usertypefun', { userType: id, proxyType: 3 })
      // }
    }
    const nextusertypefun = () => {
      context.emit('nextusertypefun', {
        user: userValue,
        proxy: proxyValue
      })

    }
    return {
      selectOptions, colNum, nextusertypefun, constUserTypeFun, getUserTypeDetail, userValue, proxyValue
    }
  }
}
